var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('div',{staticClass:"d-inline-flex align-center"},[_c('h3',{staticClass:"mr-2 mb-0"},[_vm._v(" 태그 타겟: "+_vm._s(_vm.campaign.isTagFilter ? '활성' : '비활성')+" ")]),_c('v-switch',{staticClass:"ma-0",attrs:{"hide-details":"","color":"primary","inset":""},model:{value:(_vm.campaign.isTagFilter),callback:function ($$v) {_vm.$set(_vm.campaign, "isTagFilter", $$v)},expression:"campaign.isTagFilter"}})],1)]),(_vm.campaign.isTagFilter)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',[_c('h3',[_vm._v("타겟 방식")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"d-inline-block mt-0",attrs:{"row":"","error-messages":errors},model:{value:(_vm.campaign.targetTagsMode),callback:function ($$v) {_vm.$set(_vm.campaign, "targetTagsMode", $$v)},expression:"campaign.targetTagsMode"}},_vm._l((_vm.TargetTagsModeList),function(mode,index){return _c('v-radio',{key:index,staticClass:"ml-3",attrs:{"value":mode.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(mode.text)+" "),_c('InformationHover',{attrs:{"title":mode.text,"subTitle":mode.comment}})],1)]},proxy:true}],null,true)})}),1)]}}],null,false,2389465505)})],1)],1),_c('v-row',[_c('v-col',[_c('h3',[_vm._v("타겟 조회 기간")]),_c('validation-provider',{attrs:{"rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-slider',{staticStyle:{"padding-left":"120px"},attrs:{"thumb-size":45,"min":10,"max":120,"type":"number","step":"10","color":"success","ticks":"always","tick-size":"4","thumb-label":"always","error-messages":errors,"disabled":_vm.targetTagLoading},on:{"change":_vm.changeTargetTag},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
                var value = ref.value;
return [_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(value))]),_vm._v("일 ")]}}],null,true),model:{value:(_vm.campaign.targetTagsRange),callback:function ($$v) {_vm.$set(_vm.campaign, "targetTagsRange", $$v)},expression:"campaign.targetTagsRange"}})]}}],null,false,2578847714)})],1)],1),_c('v-row',[_c('v-col',[_c('h3',[_vm._v("타겟 클릭 필터")]),_c('validation-provider',{attrs:{"rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-slider',{staticStyle:{"padding-left":"120px"},attrs:{"thumb-size":45,"min":1,"max":10,"type":"number","step":"1","color":"info","ticks":"always","tick-size":"4","thumb-label":"always","error-messages":errors,"disabled":_vm.targetTagLoading},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
                var value = ref.value;
return [_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(value))]),_vm._v("회 ")]}},{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.clickDetailTableBottomSheet = !_vm.clickDetailTableBottomSheet}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('span',[_vm._v("상세보기")])])]},proxy:true}],null,true),model:{value:(_vm.campaign.targetTagsClick),callback:function ($$v) {_vm.$set(_vm.campaign, "targetTagsClick", $$v)},expression:"campaign.targetTagsClick"}})]}}],null,false,3427453803)})],1)],1),_c('v-row',[_c('v-col',[_c('h3',[_vm._v("타겟 필터 유형")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"error-messages":errors,"row":""},on:{"change":_vm.setTargetTagsCount},model:{value:(_vm.campaign.targetTagsType),callback:function ($$v) {_vm.$set(_vm.campaign, "targetTagsType", $$v)},expression:"campaign.targetTagsType"}},_vm._l((_vm.TargetTagsTypeList),function(type,index){return _c('v-radio',{key:index,staticClass:"ml-5 mb-5",attrs:{"value":type.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('span',[_vm._v(" "+_vm._s(type.text)+" "),_c('InformationHover',{attrs:{"title":type.text,"subTitle":type.comment}})],1),_c('div',{staticClass:"d-block"},[(_vm.campaign.isTagFilter)?_c('v-chip',{attrs:{"label":"","outlined":""}},[_c('span',{staticClass:"mr-2"},[_vm._v(" 예상 도달 수: ")]),(_vm.targetTagLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","width":3,"size":20,"color":"primary"}}):_c('div',[(type.value === 'A')?_c('div',[_c('span',{staticClass:"title font-italic"},[_vm._v(" "+_vm._s(_vm.targetTagsAndCount.toLocaleString())+" ")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.andPer)+"%")])]):_vm._e(),(type.value === 'O')?_c('div',[_c('span',{staticClass:"title font-italic"},[_vm._v(" "+_vm._s(_vm.targetTagsOrCount.toLocaleString())+" ")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.orPer)+"%")])]):_vm._e()])],1):_vm._e()],1)])]},proxy:true}],null,true)})}),1)]}}],null,false,2528988876)})],1)],1),_c('v-row',[_c('v-col',[_c('h3',[_vm._v("타겟 태그")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.filteredTargetTags,"error-messages":errors,"item-text":"name","item-value":"id","hide-no-data":"","clearable":"","deletable-chips":"","required":"","multiple":"","chips":"","placeholder":"타겟을 적용할 태그를 선택하세요","hide-selected":"","color":"#FF007F","return-object":"","prepend-inner-icon":"mdi-tag-heart","search-input":_vm.targetTagSearchInput,"loading":_vm.targetTagLoading,"disabled":_vm.targetTagLoading,"disable-lookup":_vm.targetTagLoading},on:{"update:searchInput":function($event){_vm.targetTagSearchInput=$event},"update:search-input":function($event){_vm.targetTagSearchInput=$event},"change":_vm.changeTargetTag},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"label":"","outlined":"","close":"","color":"#FF007F"},on:{"click":data.select,"click:close":function () {
                          _vm.removeTargetTag(data.item);
                        }}},'v-chip',data.attrs,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pound ")]),_c('span',[_vm._v(_vm._s(data.item.name))])],1)]}},{key:"item",fn:function(data){return [(typeof data.item === 'object')?[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(("" + (data.item.name)))}})],1)]:_vm._e()]}}],null,true),model:{value:(_vm.campaign.targetTags),callback:function ($$v) {_vm.$set(_vm.campaign, "targetTags", $$v)},expression:"campaign.targetTags"}})]}}],null,false,2287977690)})],1)],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("수집 태그")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.filteredTags,"error-messages":errors,"item-text":"name","item-value":"id","hide-no-data":"","clearable":"","deletable-chips":"","required":"","multiple":"","chips":"","placeholder":"수집 태그를 선택하세요","hide-selected":"","return-object":"","prepend-inner-icon":"mdi-tag","search-input":_vm.tagSearchInput},on:{"update:searchInput":function($event){_vm.tagSearchInput=$event},"update:search-input":function($event){_vm.tagSearchInput=$event},"change":function($event){_vm.tagSearchInput = ''}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"label":"","outlined":"","close":"","color":"secondary"},on:{"click":data.select,"click:close":function () {
                      _vm.remove(data.item);
                    }}},'v-chip',data.attrs,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pound ")]),_c('span',[_vm._v(_vm._s(data.item.name))])],1)]}},{key:"item",fn:function(data){return [(typeof data.item === 'object')?[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(("" + (data.item.name)))}})],1)]:_vm._e()]}}],null,true),model:{value:(_vm.campaign.tags),callback:function ($$v) {_vm.$set(_vm.campaign, "tags", $$v)},expression:"campaign.tags"}})]}}])})],1)],1)],1)],1),_c('v-bottom-sheet',{attrs:{"inset":"","hide-overlay":""},model:{value:(_vm.clickDetailTableBottomSheet),callback:function ($$v) {_vm.clickDetailTableBottomSheet=$$v},expression:"clickDetailTableBottomSheet"}},[_c('v-card',{staticStyle:{"overflow-y":"auto"},attrs:{"flat":"","height":"600px","max-height":"600px","width":"100%"}},[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("타겟 클릭 세부정보")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.clickDetailTableBottomSheet = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('h3',[_vm._v("AND 필터")]),_c('Table',{staticClass:"table-striped-rows",attrs:{"columns":_vm.clickColumns,"data-source":_vm.targetTagsAnd,"size":"small","pagination":false,"rowKey":function (record, index) {
                  return ("and-" + (record.click));
                }},scopedSlots:_vm._u([{key:"localeString",fn:function(value){return [_vm._v(" "+_vm._s(value > 0 ? value.toLocaleString() : '-')+" ")]}},{key:"percent",fn:function(value){return [_vm._v(" "+_vm._s(value)+" % ")]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('h3',[_vm._v("OR 필터")]),_c('Table',{staticClass:"table-striped-rows",attrs:{"columns":_vm.clickColumns,"data-source":_vm.targetTagsOr,"size":"small","pagination":false,"rowKey":function (record, index) {
                  return ("or-" + (record.click));
                }},scopedSlots:_vm._u([{key:"localeString",fn:function(value){return [_vm._v(" "+_vm._s(value > 0 ? value.toLocaleString() : '-')+" ")]}},{key:"percent",fn:function(value){return [_vm._v(" "+_vm._s(value)+" % ")]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }